<template>
  <section class="mt-3">
    <div class="container">
      <div class="columns is-justify-content-center">
        <div class="column is-4">
          <b-loading :active="isLoading" />
          <div class="card mb-5">
            <header class="card-header">
              <p class="card-header-title">Aufladebetrag</p>
            </header>
            <div class="card-content">
              <div
                class="columns is-mobile"
                v-for="(item, index) in calculatorItems"
                :key="index"
              >
                <div class="column is-2">
                  <b-tooltip :label="item.name">
                    <b-icon pack="far" :icon="item.icon" size="is-medium" />
                  </b-tooltip>
                </div>
                <div class="column">
                  <b-numberinput
                    icon-pack="far"
                    v-model="item.amount"
                    min="0"
                    step="1"
                    size="is-small"
                    @input="updateRechargeAmount"
                  />
                </div>
                <div class="column is-3 has-text-right">
                  {{ Number(item.amount * item.price).toFixed(2) }}&euro;
                </div>
              </div>
              <b-field label="Aufzuladender Betrag">
                <b-input
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="rechargeAmount"
                  expanded
                  @input="resetCalculatorItems"
                ></b-input>
                <p class="control">
                  <span class="button is-static">EUR</span>
                </p>
              </b-field>
            </div>
          </div>

          <b-message v-if="rechargeAmount < minChargeAmount" type="is-warning"
            >Du musst mindestens {{ minChargeAmount }} Euro aufladen.</b-message
          >

          <b-message v-if="rechargeAmount > maxChargeAmount" type="is-warning"
            >Du kannst maximal {{ maxChargeAmount }} Euro aufladen.</b-message
          >

          <b-checkbox v-model="noPayout" class="mb-4">
            Ich habe zur Kenntnis genommen, dass ich aufgeladenes Guthaben nicht
            auszahlen lassen kann.
          </b-checkbox>

          <b-checkbox v-model="agb" class="mb-4">
            Hiermit bestätige ich die Geltung der
            <a
              href="https://shop.ticketingsolutions.de/7-iserlohn-roosters/agb"
              target="_blank"
              >Allgemeinen Geschäftsbedingungen</a
            >.
          </b-checkbox>
          <b-message v-if="cancelled" type="is-primary">
            {{ cancelled }}
          </b-message>
          <div id="paypal-buttons"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      agb: false,
      noPayout: false,
      minChargeAmount: 10,
      maxChargeAmount: 100,
      rechargeAmount: 0,
      cardCode: this.$route.params.id,
      isLoading: true,
      cancelled: null,
      card: null,
      credentials: {
        production: this.$store.getters.payPalClientId,
        sandbox: this.$store.getters.payPalClientId,
      },
      myStyle: {
        label: "pay",
        size: "responsive",
        shape: "rect",
        color: "black",
      },
      calculatorItems: [
        {
          icon: "beer-mug",
          price: 5,
          name: "Bier inkl. Pfand",
          amount: 0,
        },
        {
          icon: "cup-straw",
          price: 4,
          name: "Softdrink inkl. Pfand",
          amount: 0,
        },
        {
          icon: "candy",
          price: 5,
          name: "HARIBO Becher",
          amount: 0,
        },
      ],
    };
  },
  computed: {
    totalRechargePrice() {
      let totalPrice = 0;
      this.calculatorItems.forEach((item) => {
        totalPrice += item.amount * item.price;
      });

      return totalPrice;
    },
  },
  methods: {
    init() {
      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/card/" + this.cardCode)
        .then((response) => {
          if (response.status == 204) {
            this.cardStatus = 1;
            this.$router.push("/card/" + this.cardCode);
          }

          this.card = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });

      this.intervalId = setInterval(() => {
        console.log("check if element exists");
        const elementExists = !!document.getElementById("paypal-buttons");
        if (elementExists) {
          loadScript({
            "client-id":
              "AQgNT0ixd9_lUoaXG8kNKSqLGY5dB82z5DVCbrYIeuC7phtb4k1A1L0w1QKkAsnx4ewtkpB_hK7pYIrS",
            currency: "EUR",
          })
            .then((paypal) => {
              clearInterval(this.intervalId);
              paypal
                .Buttons({
                  style: {
                    size: "responsive",
                    color: "black",
                    label: "pay",
                    branding: false,
                  },
                  fundingSource: paypal.FUNDING.PAYPAL,
                  createOrder: () => {
                    if (
                      this.rechargeAmount < this.minChargeAmount ||
                      this.rechargeAmount > this.maxChargeAmount ||
                      !this.agb ||
                      !this.noPayout
                    ) {
                      this.cancelled = "Bitte fülle alle Felder richtig aus.";
                      return;
                    }

                    const payload = {
                      card_code: this.cardCode,
                      amount: this.rechargeAmount,
                    };
                    return this.$store.getters.axios
                      .post(
                        this.$store.getters.apiUrl + "/paypal/create",
                        payload
                      )
                      .then(function (res) {
                        return res.data.id;
                      });
                  },
                  onApprove: (data) => {
                    this.isLoading = true;
                    return this.$store.getters.axios
                      .post(this.$store.getters.apiUrl + "/paypal/pay", {
                        payment_id: data.paymentID,
                        payer_id: data.payerID,
                        card_code: this.cardCode,
                        amount: this.rechargeAmount,
                      })
                      .then((res) => {
                        return res.data;
                      })
                      .then(() => {
                        this.$router.push("/completion/" + this.cardCode);
                      })
                      .catch((error) => {
                        console.log(error.response);
                        this.cancelled =
                          "Bei der Verarbeitung der Aufladung ist ein Fehler aufgetreten. Bitte wende Dich an unseren Kundenservice: info@ticketingsolutions.de";
                      })
                      .finally(() => {
                        this.isLoading = false;
                      });
                  },
                })
                .render("#paypal-buttons");
            })
            .catch((error) => {
              console.error("failed to load the PayPal JS SDK script", error);
            });
        }
      }, 1000);
    },
    resetCalculatorItems() {
      this.calculatorItems.forEach((item) => {
        item.amount = 0;
      });
    },
    updateRechargeAmount() {
      this.rechargeAmount = this.totalRechargePrice;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
