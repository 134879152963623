<template>
  <div class="main">
    <div class="wrapper">
      <base-header></base-header>
      <div class="px-5">
        <router-view></router-view>
        <div class="container mt-5">
          <div class="columns is-justify-content-center">
            <div class="column is-3 has-text-centered">
              <a href="https://www.mark-e.de/" target="_blank">
                <img
                  src="https://ticketingsolutions.canto.global/direct/image/mp0cgior495b17i7h37l3dtn0l/hzTFZVuQK4JejU7PPP6_fiv5QAc/m800/800"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-footer class="py-5 px-1"></base-footer>
  </div>
</template>

<script>
import BaseFooter from "./components/BaseFooter.vue";
import BaseHeader from "./components/BaseHeader.vue";

export default {
  name: "App",
  components: {
    BaseHeader,
    BaseFooter,
  },
};
</script>

<style>
.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.wrapper {
  flex: 1;
}
</style>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: rgb(21, 63, 143);
$primary-invert: findColorInvert($primary);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "./assets/css/main.css";
</style>
