<template>
  <b-navbar wrapper-class="container">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="https://ticketingsolutions.canto.global/direct/image/fo5kdak1j546d2jmilqpuvjs1a/rnn9kv7aKd9KeVd-79ocxF-2zuk/m640/800"
          class="brand-logo"
          style="height: 5rem; max-height: 5rem"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" to="/">
        <b-icon pack="fas" icon="qrcode" class="mr-1" />Meine Feuerlöscher
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/loyalitypoints">
        <b-icon pack="fas" icon="basketball" class="mr-1" />Meine Buckets
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/login"
        v-if="$store.getters.user != null"
      >
        Abmelden
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "BaseHeader",
  components: {},
};
</script>

<style>
</style>
