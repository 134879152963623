<template>
  <b-modal
    v-model="isLinkCardModalActive"
    has-modal-card
    trap-focus
    class="px-5"
    @close="$emit('close')"
  >
    <template #default>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Dein Feuerlöscher</p>
        </header>
        <section class="modal-card-body">
          <p>
            Verknüpfe Deinen Feuerlöscher mit Deinem Kundenkonto, um von allen
            Vorteilen profitieren zu können.
          </p>
          <p class="mt-4">
            Möchtest Du Deinen Feuerlöscher mit folgendem Kundenkonto
            verknüpfen:
          </p>
          <p class="mt-2">
            <span class="has-text-weight-bold"
              >{{ $store.getters.user.payload.first_name }}
              {{ $store.getters.user.payload.last_name }}</span
            ><br />
            {{ $store.getters.user.payload.mail }}
          </p>
        </section>
        <footer class="modal-card-foot">
          <b-button
            :loading="isLoading"
            label="Jetzt verknüpfen!"
            type="is-primary"
            @click="$emit('linkCard')"
          ></b-button>
          <b-button
            :loading="isLoading"
            label="Abbrechen"
            type="is-light"
            @click="$emit('close')"
          ></b-button>
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "LinkCardModal",
  components: {},
  props: {
    isLinkCardModalActive: {
      type: Boolean,
      default: false,
    },
    cardCode: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
};
</script>
