<template>
  <section class="mt-3">
    <div class="container">
      <router-link :to="'/card/' + cardCode" class="button mb-4"
        >Zurück</router-link
      >
      <b-table
        :data="transactions"
        :loading="isLoading"
        paginated
        :per-page="10"
        icon-pack="fas"
      >
        <b-table-column label="Buchungs-Nr." v-slot="props">
          #{{ props.row.customer_order_id }}
        </b-table-column>
        <b-table-column label="Buchungsdatum" v-slot="props"
          >{{ getInLocalTimezone(props.row.created) }} Uhr</b-table-column
        >
        <b-table-column label="Betrag" v-slot="props" numeric
          >{{ Number(props.row.value).toFixed(2) }} &euro;</b-table-column
        >
        <b-table-column label="Status" v-slot="props">
          <b-tag v-if="props.row.payment_status.id == 1" type="is-info"
            >Vorgemerkt</b-tag
          >
          <b-tag v-if="props.row.payment_status.id == 2" type="is-success"
            >Gebucht</b-tag
          >
          <b-tag v-if="props.row.payment_status.id == 3" type="is-warning"
            >Abgebrochen</b-tag
          >
        </b-table-column>
        <b-table-column v-slot="props">
          <router-link
            :to="'/transactions/' + cardCode + '/' + props.row.id"
            class="button"
            ><b-icon icon="list" pack="fas"></b-icon
          ></router-link>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered is-size-1">
            <b-icon pack="fas" icon="cart-circle-xmark" />
          </div>
          <p class="has-text-centered">Hier gibt es noch keine Buchungen!</p>
          <p class="mt-5">
            Lade Deinen digitalen Feuerlöscher online oder am Spieltag in der
            Halle auf, um direkt starten zu können.
          </p>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { dateMixin } from "../mixins/DateMixin.js";

export default {
  name: "Transactions",
  mixins: [dateMixin],
  components: {},
  data() {
    return {
      cardCode: this.$route.params.id,
      transactions: [],
      isLoading: true,
    };
  },
  methods: {
    init() {
      this.isLoading = true;

      this.$store.getters.axios
        .get(
          this.$store.getters.apiUrl +
            "/card/" +
            this.cardCode +
            "/transactions"
        )
        .then((response) => {
          console.log(response);
          this.transactions = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    openReceipt(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
