<template>
  <section class="mt-3">
    <div class="container">
      <h3 class="has-text-black mb-4">Deine Buckets</h3>
      <div class="columns">
        <div class="column is-4">
          <div class="card mb-5">
            <div class="card-content">
              <div class="columns is-mobile">
                <div class="column is-3">
                  <b-icon pack="fas" icon="basketball" size="is-large" />
                </div>
                <div class="column is-9">
                  <span class="has-text-weight-bold has-text-black">{{
                    Number(loyalityPoints).toLocaleString()
                  }}</span
                  ><br />
                  Buckets
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 class="has-text-black mb-4">Verfügbare Prämien</h3>
      <div class="columns is-multiline">
        <div class="column is-4" v-for="(bonus, index) in bonuses" :key="index">
          <bonus-card
            :bonus="bonus"
            class="mb-4"
            @success="loadLoyalityPoints()"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BonusCard from "./bonus/BonusCard.vue";
export default {
  name: "Home",
  components: { BonusCard },
  data() {
    return {
      isLoading: false,
      loyalityPoints: 0,
      bonuses: [],
    };
  },
  methods: {
    init() {
      if (this.$store.getters.user != null) {
        this.loadLoyalityPoints();
      }

      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/bonuses")
        .then((response) => {
          this.bonuses = response.data;
        });
    },
    loadLoyalityPoints() {
      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/account/loyality")
        .then((response) => {
          this.loyalityPoints = response.data;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
