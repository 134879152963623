<template>
  <section class="mt-4 mb-4">
    <h3 class="has-text-black mt-4 mb-4">FAQs</h3>
    <div class="columns">
      <div class="column is-half">
        <b-collapse
          class="card"
          animation="slide"
          v-for="(collapse, index) of collapses"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
        >
          <template #trigger="props">
            <div class="card-header" role="button">
              <p class="card-header-title">
                {{ collapse.title }}
              </p>
              <a class="card-header-icon">
                <b-icon
                  pack="fas"
                  :icon="props.open ? 'arrow-up' : 'arrow-down'"
                >
                </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content" v-html="collapse.text"></div>
          </div>
        </b-collapse>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FAQ",
  components: {},
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: "Was passiert bei Verlust der Karte?",
          text: "<p>Wir empfehlen, den „Digitalen Feuerlöscher“ direkt mit Deinem Kundenkonto zu verknüpfen. Dann kannst Du über Deinen Kundenbereich die verknüpfte Karte direkt online sperren. Für die Verknüpfung mit einer neuen Karte wende Dich bitte am Spieltag an unsere Mitarbeiter<em>innen</em> in der Halle oder unter der Woche an die Mitarbeiter<em>innen</em> im Fanstore (Öffnungszeiten: Mittwoch – Freitag, 12:00 – 18:00 Uhr). Sollte dein „Digitaler Feuerlöscher“ verloren gehen, ohne dass du ihn mit deinem Kundenkonto verknüpft hast, ist das Guthaben leider nicht wiederherstellbar. Das gilt ebenso für Missbrauch der Karte durch Dritte, weswegen wir darauf hinweisen, den QR-Code nicht abzufotografieren oder im Internet zu veröffentlichen – und natürlich gut auf deinen „Digitalen Feuerlöscher“ aufzupassen.</p>",
        },
        {
          title:
            "Gilt der „Digitale Feuerlöscher“ auch als Zahlungsmittel für die bunte Tüte?",
          text: "<p>Ja. Alle an der langen Theke im Foyer sowie am Ausschankwagen angebotenen Speisen und Getränke können mit dem „Digitalen Feuerlöscher“ bezahlt werden. Einzig am Stand von Café Butera ist ausschließlich Barzahlung möglich.</p>",
        },
        {
          title: "Wie funktioniert die Rückzahlung des Becher-Pfand?",
          text: "<p>Jeglicher Pfand wird nicht zurück auf die Karte überwiesen, sondern bar ausgezahlt.</p>",
        },
        {
          title: "Ist die Karte auch 2025/2026 nutzbar?",
          text: "<p>Ja, solange sie mit deinem Kundenkonto verbunden bleibt.</p>",
        },
        {
          title:
            "Kann ich zwei oder mehr Karten mit einem Nutzer-Konto verknüpfen?",
          text: "<p>Ja. Nach Eingabe der Login-Daten des Kunden-Kontos kannst du mehrere Karten auf einem Account „zusammenführen“.</p>",
        },
        {
          title:
            "Kann ich auf dem „Digitalen Feuerlöscher“ befindliches Restguthaben auszahlen lassen?",
          text: "<p>Nein. Der „Digitale Feuerlöscher“ ist ein Wertgutschein und kann somit nicht wieder ausgezahlt werden. <strong>Wichtig</strong>: Bei Rückgabe der Karte erhältst du dein initialen Pfand (3,- Euro) selbstverständlich zurück.</p>",
        },
        {
          title: "Welche Vorteile bringt mir der „Digitale Feuerlöscher“?",
          text: "<p>Du musst nicht regelmäßig zum Feuerlöscher-Stand gehen, um dir eine neue Karte zu holen. Stattdessen kannst du das Guthaben jederzeit aufladen – von daheim, auf dem Weg zur Theke, kurz: wann immer du möchtest. Wenn du ein Konto verknüpft hast, kannst du dir den QR-Code deines „Digitalen Feuerlöschers“ auch im Browser auf dem Handy anzeigen lassen (oder als Screenshot speichern). Du musst also nicht jedesmal eine neue Karte holen, falls du sie einmal daheim vergessen haben solltest.</p>",
        },
      ],
    };
  },
};
</script>

<style></style>
